import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useColorMode } from "theme-ui"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import AudioPlayer from "../../../components/audio-player"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import TextLink from "../../../components/textlink"

import KarteOrte from "../../../images/leichte-sprache/leichte-karte.svg"
import KarteOrteLight from "../../../images/leichte-sprache/leichte-karte-light.svg"

const LeichteSpracheOrte = () => {
  const [colorMode] = useColorMode()
  const data = useStaticQuery(graphql`
    query LeichteSpracheOrteIndexQuery {
      bueste: file(relativePath: { eq: "leichte-sprache/bueste-gontard.jpg" }) {
        ...largeImage
      }
      lauffen: file(relativePath: { eq: "leichte-sprache/lauffen.jpg" }) {
        ...mediumImage
      }
      badHomburg: file(
        relativePath: { eq: "leichte-sprache/bad-homburg.jpg" }
      ) {
        ...mediumImage
      }
      jena: file(relativePath: { eq: "leichte-sprache/jena.jpg" }) {
        ...mediumImage
      }
      nuertingen: file(relativePath: { eq: "leichte-sprache/nuertingen.jpg" }) {
        ...mediumImage
      }
      maulbronn: file(relativePath: { eq: "leichte-sprache/maulbronn.jpg" }) {
        ...mediumImage
      }
      griechenland: file(
        relativePath: { eq: "leichte-sprache/griechenland.jpg" }
      ) {
        ...mediumImage
      }
      turm: file(relativePath: { eq: "leichte-sprache/turm-heute.jpg" }) {
        ...mediumImage
      }
      stadtfriedhof: file(relativePath: { eq: "sprachen/stadtfriedhof.jpg" }) {
        ...mediumImage
      }
      stift: file(relativePath: { eq: "sprachen/evangelisches-stift.jpg" }) {
        ...mediumImage
      }
      burse: file(relativePath: { eq: "sprachen/burse.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="wegbeschreibung">
      <Seo
        title="Diese Orte waren wichtig in Hölderlins Leben"
        description="Hölderlin wandert gerne. Er will die Welt kennen∙lernen. Als junger Mann wechselt er häufig seinen Wohn∙ort. Hier finden Sie die Orte, die für Hölderlin wichtig waren."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Orte",
                link: "/leichte-sprache/ueber-hoelderlin/orte",
              },
            ]}
          />
          <PageTitle>Diese Orte waren wichtig in Hölderlins Leben</PageTitle>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/11-orte" />
          </Constrain>
          <Grid columns={[1, 2]}>
            <Stack>
              <Paragraph hyphens="no" dropcap={true}>
                Hölderlin wandert gerne. <br />
                Er will die Welt kennen∙lernen. <br />
                Als junger Mann <br />
                wechselt er häufig <br />
                seinen Wohn∙ort.
              </Paragraph>
              <Paragraph>
                Hier finden Sie die Orte, <br />
                die für Hölderlin wichtig waren.
              </Paragraph>
            </Stack>
            <img
              src={colorMode === "light" ? KarteOrteLight : KarteOrte}
              alt="Stilisierte Landkarte mit allen Hölderlin-Orten"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Stack>

        <Stack>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.lauffen.childImageSharp.gatsbyImageData}
                alt="Hölderlins Geburtshaus ist weiß gestrichen und hat hellblaue Fensterläden. Es ist drei Stockwerke hoch und hat zur Straßenseite einen ausgebauten Dach-Giebel."
                attribution="Zooey Braun"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Lauffen am Neckar
                </Heading>
                <Paragraph>Dort wird Hölderlin geboren.</Paragraph>
                <Paragraph>
                  Hölderlins Geburtshaus ist heute ein{" "}
                  <TextLink href="https://www.lauffen.de/website/de/freizeit/museen/hoelderlinhaus">
                    Museum
                  </TextLink>
                  .
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.nuertingen.childImageSharp.gatsbyImageData}
                attribution="Postkarte aus Privatbesitz"
                alt="Eine alte Postkarte zeigt ein Haus in der Nürtinger Altstadt. Das Haus hat viele Fenster mit Vorhängen. Das Hausdach hat einen schön verzierten Giebel."
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Nürtingen
                </Heading>
                <Paragraph>Dort wohnt Hölderlin als Kind.</Paragraph>
                <Paragraph>
                  Das große Haus in der Mitte vom Bild
                  <br />
                  gehört seiner Familie.
                  <br />
                  Es liegt in der Alt∙stadt von Nürtingen.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.maulbronn.childImageSharp.gatsbyImageData}
                alt="Eine alte Postkarte zeigt das Kloster und den Klosterhof. Das Kloster hat viele gebogene Fenster und spitze Türme. Auf dem Klosterhof steht ein Brunnen. Davor sieht man eine Familie und einige andere Menschen."
                attribution="DLA Marbach"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Denkendorf und Maulbronn
                </Heading>
                <Paragraph>
                  Dort besucht Hölderlin die Kloster∙schule.
                </Paragraph>
                <Paragraph>
                  Die alte Ansicht zeigt das Kloster in Maulbronn.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.jena.childImageSharp.gatsbyImageData}
                attribution="Andreas Hub"
                alt="Ein Haus mit drei Stockwerken. Das Haus hat eine kräftige, grüne Farbe. Vor dem Haus steht ein Brunnen."
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Jena
                </Heading>
                <Paragraph>
                  Dort hört Hölderlin Vor∙lesungen <br />
                  vom Philosophen Johann Gottlieb Fichte.
                </Paragraph>
                <Paragraph>
                  Das Haus von Johann Gottlieb Fichte
                  <br />
                  ist heute ein Museum.
                  <br />
                  Es heißt Romantiker∙haus und ist auf dem Bild zu sehen.
                </Paragraph>
                <Paragraph>
                  In Jena lernt Hölderlin auch <br />
                  seinen Freund Isaac von Sinclair kennen.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.bueste.childImageSharp.gatsbyImageData}
                alt="In einem Park steht ein Sockel. Darauf ist eine Steinfigur von Susette Gontard angebracht."
                attribution="Barbara Klemm"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Frankfurt
                </Heading>
                <Paragraph>
                  Dort arbeitet Hölderlin als Haus∙lehrer.
                  <br />
                  Und er verliebt sich in Susette Gontard.
                </Paragraph>
                <Paragraph>
                  Sie ist auf diesem Denk∙mal
                  <br />
                  im Park von Bad Driburg ab∙gebildet.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.badHomburg.childImageSharp.gatsbyImageData}
                attribution="Barbara Dölemeyer"
                alt="Eine lange Straße mit vielen Häusern. Die Häuser reihen sich aneinander. Das Haus im Vordergrund ist gelb und hat grüne Fensterläden. Am Ende der Straße sieht man einen hohen Aussichtsturm."
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Bad Homburg
                </Heading>
                <Paragraph>
                  Dort wohnt Hölderlin
                  <br />
                  bei seinem Freund Isaac von Sinclair.
                </Paragraph>
                <Paragraph>
                  Das Bild zeigt die Straße,
                  <br />
                  in der Hölderlin in Bad Homburg gewohnt hat.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.griechenland.childImageSharp.gatsbyImageData}
                alt="Schwarzweiß-Fotografie einer Tempelruine in Korinth"
                attribution="Barbara Klemm"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Griechenland
                </Heading>
                <Paragraph>
                  Hölderlin war nie in Griechenland. <br />
                  Aber er wäre gerne dort∙hin gegangen. <br />
                  Hölderlin schreibt oft über Griechenland. <br />
                  Er mag die alten griechischen Dichter.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Wichtige Orte in Tübingen
          </Heading>
          <Paragraph>
            Dort hat Hölderlin studiert. <br />
            Er war auch in Tübingen im Kranken∙haus. <br />
            Und er hat viele Jahre in Tübingen gewohnt.
          </Paragraph>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.stift.childImageSharp.gatsbyImageData}
                alt="Der Eingang des Studien∙haus"
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Das Evangelische Stift
                </Heading>
                <Paragraph>
                  Das Evangelische Stift ist ein Studien∙haus. <br />
                  Dort können manche Studenten wohnen. <br />
                  Es ist nur für die Studenten, <br />
                  die Pfarrer oder Religions∙lehrer werden wollen. <br />
                  Auch Hölderlin hat im Evangelischen Stift gewohnt. <br />
                  Und dort hat er seine Freunde Magenau, Neuffer, Hegel und
                  Schelling <br />
                  kennen gelernt.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.burse.childImageSharp.gatsbyImageData}
                alt=""
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Die Burse
                </Heading>
                <Paragraph>
                  Das war früher ein Kranken∙haus. <br />
                  Dort hat ein Arzt Hölderlin behandelt. <br />
                  Heute gehört das Gebäude zur Universität.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.turm.childImageSharp.gatsbyImageData}
                alt="Der Hölderlinturm am Tübinger Neckar: Ein gelbes Gebäude mit rundem Turm und Spitzdach."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Der Hölderlin∙turm
                </Heading>
                <Paragraph>
                  Dort hat Hölderlin lange Zeit bei Familie Zimmer gewohnt.{" "}
                  <br />
                  Heute ist der Turm ein Museum. <br />
                  Dort gibt es Aus∙stellungen und Veranstaltungen.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.stadtfriedhof.childImageSharp.gatsbyImageData}
                alt="Hölderlins Grabstein neben grünen Büschen und Bäumen. Oben auf dem Grabstein ist ein Kreuz."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Der Stadt∙fried∙hof
                </Heading>
                <Paragraph>Dort ist das Grab von Hölderlin.</Paragraph>
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheOrte
